:root {
    --breakpoint-sm: 560px;
    --breakpoint-md: 768px;
}

/*
Sticky header
 */
div[data-ds-id="HeaderContainer"] {
    top: 0px;
    position: sticky;
    z-index: 10;
}

div[data-ds-id="HeaderContainer"].behind-modal {
    z-index: 0;
}

div[data-ds-id="HeaderContainer"] > div[data-ds-id="Box"] {

}

.header__navigation.expandedNavMenu > div {
    width: 100vw;
}

@media (max-width: 560px) {
    div[data-ds-id="HeaderContainer"] > div {

    }

    div[data-ds-id="HeaderContainer"] div[data-ds-id="Tabs"] button {
        width: 3rem;
        justify-content: center;
    }
}


/*
Custom style to center hamburger menu
 */
.header__navigation > div[data-ds-id="Container"] > div[data-ds-id="Inline"] {
    height: inherit;
    margin: auto;
    padding: 1rem 0 0;
}

div[data-ds-id="Tabs"] button {
    padding-bottom: 24px;
}

h2[data-ds-id="TextClamped"] > span {
    max-height: 28px;
}

@media (max-width: 560px) {
    div.header__navigation.expandedNavMenu div[data-ds-id="Tabs12334"] button {
        padding-left: 10px;
        padding-right: 10px;
    }


}

.progress-container {
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    margin: 0 auto;
    width: 75%;
    max-width: 750px;
}

.step {
    display: flex;
    text-align: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    margin: auto;
}

.step.current > span.step-number {
    border: 0.2rem solid #0fa980;
}

.step:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: var(--line-right, -200px);
    width: var(--line-width, 200px);
    height: 3px;
    background-color: #D0D0D0;
    transform: translateY(-50%);
    z-index: 0;
}

.step-number {
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    border: .2rem solid #D0D0D0;
    font-weight: bold;
    margin-bottom: 5px;
}



.step-name {
    font-size: 14px;
}

.completed .step-number {
    background-color: #0fa980;
    border: 0.2rem solid #0fa980;
}

.completed .step-number span {
    color: #eee;
}

.completed:not(:last-child)::after {
    background-color: #0fa980;
}

.step > span {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center
}

.step.current > span {
/*    border: 0.2rem solid #28a745;*/
}

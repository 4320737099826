
.store-episode__main-box {
    max-width: 960px;
    margin: 0 auto;
}

.store-episode-progress__bar {
    width: 80%;
    height: 1rem;
    appearance: none;
    background: rgb(255,255,255,0.2);
    border: 0.3px solid gray;
    color: #0fa980; /* may not have an effect but worth adding */
    border-radius: 1rem;
}

.store-episode-progress__bar::-webkit-progress-bar {
    background: transparent; /* remove default background in chrome */
    border-radius: 1rem;
}

.store-episode-progress__bar::-webkit-progress-value {
    background: #0fa980;
    border-radius: 1rem;
}

.store-episode-progress__bar::-moz-progress-bar {
    background: #0fa980;
    border-radius: 1rem;
}

.article-selector__readonly-form > div[data-ds-id="FormFieldGroup"] > div[data-ds-id="Stack"] > div {
    margin-top: unset;
}

.article-selector__readonly-form > div[data-ds-id="FormFieldGroup"] > div[data-ds-id="Stack"] > div:last-child {
    margin-top: 8px;
}

.store-episode__article {
    position: relative;
}

.store-episode__article textarea {
    padding-bottom: 5rem;
}

.store-episode__article-button {
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, -0.5rem);
    max-width: 250px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 10px;
    width: 80%;
    text-align: center;
}

.store-episode__article-button--light {
background: #ffffff;
}

.store-episode__article-button--dark {
background: #24282d;
}
